import React from "react"
import { Box, Heading } from "theme-ui"

// import app components
import FormContainer from "../Form"
import Edges from "../Edges"

const Form = (props) => {
  return (
    <Box bg="blueModuleBg" sx={{ pb: "100px" }}>
      <Edges size="sm">
        <Heading
          sx={{ pt: ["70px", "120px"], width: "100%", textAlign: "center" }}
          variant="text.formHeading"
          children={props.heading}
        />
        <FormContainer {...props} />
      </Edges>
    </Box>
  )
}

export default Form
