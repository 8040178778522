import React from "react"
import { Box, Heading, Text } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Parser from "html-react-parser"

// import app components
import Edges from "../Edges"
import Link from "../Link"

const Cards = (props) => {
  const { cards } = props

  return (
    <Box
      sx={{ pt: ["50px", "110px"], pb: [, "100px", "235px"], bg: "bgWhite" }}
    >
      <Edges size="cmd">
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          {cards &&
            cards.map((o, i) => {
              const processedImage =
                !o.image?.svg && getImage(o.image.localFile)
              return (
                <Box key={i} sx={{ textDecoration: "none !important" }}>
                  {o.link && (
                    <Link to={o.link.url}>
                      <Box
                        key={i}
                        sx={{
                          color: "black",
                          textDecoration: "none !important",
                          mb: "30px",
                          bg: "white",
                          height: "100%",
                          display: "flex",
                          width: "100%",
                          justifyContent: "center",
                          flexDirection: "column",
                          minHeight: "200px",
                          transition: "box-shadow 0.5s ease-in-out",
                          borderRadius: "3px",
                          boxShadow: "0px 3px 6px #00000029",
                          "&:hover,&:focus": {
                            transform: "scale3d(1.0001, 1.0001, 1)",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            "&:after": {
                              opacity: 1,
                            },
                          },
                        }}
                      >
                        <Box
                          sx={{
                            display: ["flex", "grid"],
                            flexDirection: "column",
                            gridTemplateColumns: ["1fr", "40% 60%", "35% 65%"],
                            "@media (max-width:750px)": {
                              gridTemplateColumns: "1fr",
                              gridTemplateRows: "auto",
                              height: "100%",
                            },
                            gap: 0,
                          }}
                        >
                          <Box
                            sx={{
                              m: "30px",
                              position: "relative",
                              height: "100%",
                              width: "100%",
                              my: "auto",
                              "@media (max-width:750px)": {
                                mx: "auto",
                              },
                              maxWidth: "220px",
                              ".gatsby-image-wrapper": {
                                maxWidth: "170px",
                                height: "170px",
                              },
                              svg: {
                                maxWidth: "170px",
                                height: "170px",
                              },
                            }}
                          >
                            {o.image && (
                              <>
                                {o.image?.svg ? (
                                  Parser(o.image.svg)
                                ) : (
                                  <GatsbyImage
                                    image={processedImage}
                                    alt={""}
                                  />
                                )}
                              </>
                            )}
                          </Box>

                          <Box
                            sx={{
                              height: "100%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              width: "100%",
                              pr: ["30px", "50px"],
                              my: "auto",
                              pl: ["30px", "10px"],
                              "@media (max-width:750px)": {
                                p: "30px",
                              },
                            }}
                          >
                            {o.headline && (
                              <Heading
                                variant="styles.cardHeading"
                                children={Parser(o.headline)}
                              />
                            )}
                            {o.text && (
                              <Text
                                variant="styles.p"
                                children={Parser(o.text)}
                                sx={{ pt: "20px" }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>
                    </Link>
                  )}
                </Box>
              )
            })}
        </Box>
      </Edges>
    </Box>
  )
}

export default Cards
