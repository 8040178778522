/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Heading, Box } from "theme-ui"

// import app components
import BackgroundImage from "../BackgroundImage"
import BackgroundVideo from "../BackgroundVideo"
import Button from "../Button"
import Textarea from "../Textarea"

const Hero = (props) => {
  const { text, image, filevideo, link, headline } = props

  return (
    <Container>
      <Box
        sx={{
          position: "relative",
          flexDirection: "column",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          zIndex: 10,
          color: "white",
          textAlign: "center",
          "div > *": {
            color: "white",
          },
        }}
      >
        <Box sx={{ display: "flex", flexDirectionL: "column", width: "100%" }}>
          {headline && (
            <Heading
              variant="styles.heroHeading"
              sx={{
                pb: [2, 2],
                mx: "auto",
                width: "100%",
              }}
            >
              {headline}
            </Heading>
          )}
        </Box>

        <Box sx={{ maxWidth: "600px" }}>
          {text && (
            <Textarea
              sx={{
                px: ["10px", null],
                pb: "30px",
                textAlign: "center",
                h3: {
                  variant: "styles.heroText",
                },
              }}
              content={text}
            />
          )}
        </Box>
        {link && link.title && <Button to={link.url}>{link.title}</Button>}
      </Box>
      <MediaContainer>
        {(filevideo?.localFile?.publicURL || filevideo?.url) && (
          <BackgroundVideoContainer>
            <BackgroundVideo
              src={filevideo?.localFile?.publicURL || filevideo?.url}
            />
          </BackgroundVideoContainer>
        )}
        {image && <BackgroundImage image={image} sx={{ zIndex: 1 }} />}
      </MediaContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  height: ${({ theme: { headerHeight } }) => `calc(100vh + ${headerHeight}px)`};
  display: flex;
  align-items: center;
`

const MediaContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  ::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #012040;
    mix-blend-mode: multiply;
    opacity: 0.5;
  }
`

const BackgroundVideoContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
`

const Content = styled.div`
  position: relative;
  z-index: 4;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export default Hero
