/** @jsx jsx */
import { jsx } from "theme-ui"
import styled from "@emotion/styled"
import { Box } from "theme-ui"

// import app components
import Edges from "../Edges"
import BackgroundImage from "../BackgroundImage"
import Textarea from "../Textarea"
import Form from "../Form"

const LocationHero = (props) => {
  const { text, image } = props

  return (
    <Container>
      <Edges size="lg">
        <Box
          sx={{
            position: "relative",
            zIndex: 10,
            color: "white",
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            minHeight: [null, null, `calc(100vh - 160px)`],
            "p:last-child": {
              mb: [16, 18, 24],
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              zIndex: 2,
              "@media (min-width: 980px)": {
                width: "50%",
              },

              width: "100%",
              height: "100%",

              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              "div > *": {
                color: "white",
                h2: {
                  variant: "styles.locationHero",
                },
              },
            }}
          >
            {text && <Textarea content={text} />}
          </Box>
          <Box
            sx={{
              "@media (min-width: 980px)": {
                width: "50%",
              },

              width: "100%",
            }}
          >
            <Form formid="1" heading="Contact Us" />
          </Box>
        </Box>
      </Edges>
      <MediaContainer>
        {image && <BackgroundImage image={image} sx={{ zIndex: 1 }} />}
      </MediaContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  @media (max-width: 980px) {
    height: 100%;
  }
  height: ${({ theme: { headerHeight } }) => `calc(100vh - 150px)`};
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
`

const MediaContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  ::after {
    content: " ";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background: #393633;
    mix-blend-mode: multiply;
    opacity: 0.75;
  }
`

export default LocationHero
