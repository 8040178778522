import React from "react"
import { Box, Grid, Text, Link as ThemeLink, Embed } from "theme-ui"
import Parser from "html-react-parser"
import Textarea from "../Textarea"

// import app components
import Edges from "../Edges"
import LocationIcon from "../../icons/location.svg"

const Locations = (props) => {
  const { location } = props

  return (
    <Box pt={2}>
      <Edges size="lg">
        <Grid gap={3} columns={[1, 2, 3]} pt={[100, 150]}>
          {location &&
            location.map((item, idx) => {
              return (
                <Box
                  key={idx}
                  // px={[10, "10px"]}
                  pb={[70, "null"]}
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box sx={{ svg: { height: 30, width: 30 } }}>
                    {item.mapLink && (
                      <iframe
                        src={item.mapLink}
                        width="100%"
                        height="400"
                        style={{ border: 0 }}
                        loading="lazy"
                      ></iframe>
                    )}
                  </Box>
                  {/* <Textarea content={item.map} /> */}

                  {item.name && (
                    <Text
                      variant="text.formHeading"
                      sx={{ color: "black" }}
                      pt={30}
                      children={Parser(item.name)}
                    />
                  )}

                  {item.number && (
                    <ThemeLink
                      variant="text.locationAddress"
                      pt={[40, 50, 50]}
                      children={Parser(item.number)}
                      sx={{
                        textDecoration: "none",
                        "&:hover": { color: "primary" },
                        maxWidth: 200,
                      }}
                      href={`tel:${Parser(item.number.replace(/\./g, ""))}`}
                    />
                  )}
                  {item.address && (
                    <Text
                      variant="text.locationAddress"
                      children={Parser(item.address)}
                    />
                  )}
                  {item.weekdays && (
                    <Text
                      variant="text.paragraph"
                      pt={15}
                      children={Parser(item.weekdays)}
                      color={"textBlack"}
                    />
                  )}
                  {item.weekend && (
                    <Text
                      variant="text.paragraph"
                      children={Parser(item.weekend)}
                    />
                  )}
                  {item.closing && (
                    <Text
                      variant="text.paragraph"
                      pt={15}
                      children={Parser(item.closing)}
                    />
                  )}
                </Box>
              )
            })}
        </Grid>
      </Edges>
      {/* <Box>
        {mapLink && (
          <iframe
            src={mapLink}
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
        )}
      </Box> */}
    </Box>
  )
}

export default Locations
