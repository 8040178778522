import React from "react"
import { Box, Grid, Heading } from "theme-ui"

// import app components
import BackgroundImage from "../BackgroundImage"
import Link from "../Link"
import InstagramIcon from "../../icons/instagram.svg"

const Gallery = (props) => {
  const { images, twitterlink } = props

  return (
    <Box pt={2} pb={["60px", "130px"]}>
      <Grid gap={2} columns={[1, 2, 4]}>
        {images &&
          images.map((o, i) => {
            return (
              <Box key={i} sx={{ position: "relative", height: [300, 350] }}>
                <Link to={o.url}>
                  {o.image && <BackgroundImage image={o.image} />}
                </Link>
              </Box>
            )
          })}
      </Grid>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          pt: ["50px", "110px"],
          svg: {
            width: 35,
            height: 28,
            path: {
              fill: "primary",
            },
          },
        }}
      >
        <Link to={twitterlink}>
          <Box sx={{ "&:hover": { svg: { path: { fill: "twitterBlue" } } } }}>
            <InstagramIcon />

            <Heading
              sx={{ pt: "30px", width: "100%", textAlign: "center" }}
              variant="text.galleryLink"
              children="Follow us"
            />
          </Box>
        </Link>
      </Box>
    </Box>
  )
}

export default Gallery
