import React from "react"
import { Box, Heading, Text } from "theme-ui"
// import Embed from "react-embed"
import Parser from "html-react-parser"

// import app components
import Edges from "../Edges"
// import Textarea from "../Textarea"
// import Button from "../Button"
// import { parser } from "@apollo/react-hooks"
import LogoArrow from "../../icons/arrow.svg"
import Link from "../Link"

const CallToAction = (props) => {
  const { headline, text, link, backgroundcolor } = props

  return (
    <Box
      sx={{
        bg: `${backgroundcolor === "white" ? "white" : "blueModuleBg"}`,
        py: 6,
      }}
    >
      <Edges size="md">
        <Box sx={{ textAlign: "center" }}>
          {headline && (
            <Heading
              variant="text.callToActionHeading"
              sx={{
                color: `${backgroundcolor === "white" ? "text" : "white"}`,
                pb: 24,
              }}
            >
              {Parser(headline)}
            </Heading>
          )}
          {text && (
            <Text
              color={`${backgroundcolor === "white" ? "text" : "muted"}`}
              variant="text.callToActionText"
            >
              {Parser(text)}
            </Text>
          )}
          {link && link.title && (
            <Box sx={{ pt: 24 }}>
              <Link to={link.url}>
                <Box
                  aria-label="Read article"
                  sx={{
                    a: {
                      textDecoration: "none",
                    },
                    display: "flex",
                    justifyContent: "center",
                    color: "primary",
                    svg: {
                      width: "80px",
                      height: "20px",
                      my: "auto",
                      transform: "translateY(-3px)",
                    },
                  }}
                >
                  <Text variant="text.productsMore"> {link.title}</Text>
                  <LogoArrow />
                </Box>
              </Link>
            </Box>
          )}
        </Box>
      </Edges>
    </Box>
  )
}

export default CallToAction
