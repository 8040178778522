import React from "react"
import { Box, Heading } from "theme-ui"

// import app components
import Textarea from "../Textarea"
import BackgroundImage from "../BackgroundImage"
import Edges from "../Edges"
import Button from "../Button"
import Link from "../Link"

const ContentImage = (props) => {
  const { sections, headline, url } = props

  return (
    <>
      {sections &&
        sections.map((o, i) => (
          <Box
            key={i}
            sx={{
              bg: `${o.backgroundcolor === "white" ? "white" : "lightBlue"}`,
              position: "relative",
              display: ["flex", "flex", "grid"],
              gridTemplateColumns: "1fr 1fr",
              gridTemplateRows: "1fr",
              flexWrap: "wrap-reverse",
              alignItems: "center",
              minHeight: [null, null, `calc(100vh - 160px)`],
              "p:last-child": {
                mb: [16, 18, 24],
              },
            }}
          >
            <Box
              sx={{
                gridColumnStart: `${o.alignimage === "Right" ? 2 : 1}`,
                gridColumnEnd: `${o.alignimage === "Right" ? 3 : 2}`,
                gridRowStart: 1,
                gridRowEnd: 2,
                left: o.alignimage === "right" ? "unset" : 0,
                right: o.alignimage === "left" ? "unset" : 0,
                position: ["relative", null, "absolute"],
                height: [300, 400, "auto"],
                width: "100%",
                order: 1,
                top: [null, null, 0],
                bottom: [null, null, 0],
              }}
            >
              {o.image && <BackgroundImage image={o.image} />}
            </Box>
            <Box
              sx={{
                gridColumnStart: `${o.alignimage === "Right" ? 1 : 2}`,
                gridColumnEnd: `${o.alignimage === "Right" ? 2 : 3}`,
                gridRowStart: 1,
                gridRowEnd: 2,
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                py: ["30px", "null"],
                px: ["null", "80px"],
                "@media (min-width: 1100px)": {
                  pl: [
                    "null",
                    `${o.alignimage === "Right" ? "180px" : "80px"}`,
                  ],
                  pr: [
                    "null",
                    `${o.alignimage === "Right" ? "80px" : "180px"}`,
                  ],
                },
              }}
            >
              <Edges size="md">
                {headline && (
                  <Link to={url} style={{ textDecoration: "none" }}>
                    <Heading as="h3" mb={2} color="primary">
                      {headline}
                    </Heading>
                  </Link>
                )}
                <Box
                  sx={{
                    "div > *": {
                      color: `${
                        o.backgroundcolor === "white" ? "text" : "white"
                      }`,
                    },
                  }}
                >
                  {o.text && <Textarea content={o.text} />}
                </Box>
                {o.link && o.link.title && (
                  <Button customeVariant="buttons.secondary" to={o.link.url}>
                    {o.link.title}
                  </Button>
                )}
              </Edges>
            </Box>
          </Box>
        ))}
    </>
  )
}
export default ContentImage
