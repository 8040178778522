import React from "react"
import { graphql } from "gatsby"
import { Box } from "theme-ui"

// import app components
import Layout from "../../components/Layout"
import textimage from "../../components/textImage/TextImage"
import hero from "../../components/hero/Hero"
import text from "../../components/text/Text"
import introduction from "../../components/introduction/Introduction"
import cards from "../../components/cards/Cards"
import form from "../../components/form/Form"
import calltoaction from "../../components/callToAction/CallToAction"
import contentimage from "../../components/contentImage/ContentImage"
import locations from "../../components/locations/Locations"
import gallery from "../../components/gallery/Gallery"
import locationhero from "../../components/locationHero/LocationHero"

const blocks = {
  textimage,
  hero,
  text,
  introduction,
  cards,
  form,
  calltoaction,
  contentimage,
  locations,
  gallery,
  locationhero,
}

const Template = (props) => {
  const {
    data: {
      wpPage: {
        template: { acf },
      },
    },
  } = props

  return (
    <Layout {...props} isHomePage={props?.location?.pathname === "/"}>
      {acf?.content?.flex &&
        acf.content.flex.map(
          ({ id: fieldId, fieldGroupName, ...rest }, index) => {
            const id = fieldId || fieldGroupName?.split("_").pop().toLowerCase()

            const Component = blocks?.[id]
            return (
              Component && (
                <Box key={index}>
                  <Component {...rest} />
                </Box>
              )
            )
          }
        )}
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query DefaultPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      uri
      slug
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      template {
        ... on WpDefaultTemplate {
          templateName
          acf {
            content {
              flex {
                ... on WpDefaultTemplate_Acf_Content_Flex_Hero {
                  fieldGroupName
                  link {
                    target
                    title
                    url
                  }
                  text
                  headline
                  filevideo {
                    altText
                    uri
                    localFile {
                      publicURL
                    }
                  }
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 1920
                          placeholder: BLURRED
                          quality: 100
                        )
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Locationhero {
                  fieldGroupName
                  text
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 1920
                          placeholder: BLURRED
                          quality: 100
                        )
                      }
                    }
                  }
                }

                ... on WpDefaultTemplate_Acf_Content_Flex_Calltoaction {
                  fieldGroupName
                  text
                  headline
                  backgroundcolor
                  link {
                    target
                    title
                    url
                  }
                }

                ... on WpDefaultTemplate_Acf_Content_Flex_Introduction {
                  fieldGroupName
                  text
                  backgroundcolor
                  image {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          width: 600
                          placeholder: BLURRED
                          quality: 100
                        )
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Form {
                  fieldGroupName
                  formid
                  heading
                }

                ... on WpDefaultTemplate_Acf_Content_Flex_Text {
                  fieldGroupName
                  text
                }

                ... on WpDefaultTemplate_Acf_Content_Flex_Textimage {
                  fieldGroupName
                  sections {
                    text
                    backgroundcolor
                    alignimage
                    link {
                      target
                      title
                      url
                    }
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 900
                            placeholder: BLURRED
                            quality: 100
                          )
                        }
                      }
                    }
                    image2 {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 900
                            placeholder: BLURRED
                            quality: 100
                          )
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Contentimage {
                  fieldGroupName
                  sections {
                    text
                    backgroundcolor
                    alignimage
                    link {
                      target
                      title
                      url
                    }
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 600
                            placeholder: BLURRED
                            quality: 100
                          )
                        }
                      }
                    }
                  }
                }

                ... on WpDefaultTemplate_Acf_Content_Flex_Gallery {
                  fieldGroupName
                  twitterlink
                  images {
                    fieldGroupName
                    url
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 550
                            placeholder: BLURRED
                            quality: 100
                          )
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Cards {
                  fieldGroupName
                  cards {
                    fieldGroupName
                    link {
                      target
                      title
                      url
                    }
                    headline
                    text
                    image {
                      altText
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            width: 220
                            placeholder: BLURRED
                            quality: 100
                          )
                        }
                      }
                    }
                  }
                }
                ... on WpDefaultTemplate_Acf_Content_Flex_Locations {
                  fieldGroupName

                  location {
                    fieldGroupName
                    name
                    number
                    address
                    weekdays
                    weekend
                    closing
                    mapLink
                  }
                }
              }
            }
            fieldGroupName
          }
        }
      }
    }
  }
`

export default Template
